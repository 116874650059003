import { render, staticRenderFns } from "./paymentRecords.vue?vue&type=template&id=71776b76&scoped=true&"
import script from "./paymentRecords.vue?vue&type=script&lang=js&"
export * from "./paymentRecords.vue?vue&type=script&lang=js&"
import style0 from "./paymentRecords.vue?vue&type=style&index=0&id=71776b76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71776b76",
  null
  
)

export default component.exports