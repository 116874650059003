<template>
  <div class="appointmentManagement-view">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="入会审核" name="first"
      ><ship v-if="activeName == 'first'" :jurisdiction="jurisdiction"
      /></el-tab-pane>
      <el-tab-pane label="缴费记录" name="second"
      ><paymentRecords
          v-if="activeName == 'second'"
          :jurisdiction="jurisdiction"
      ></paymentRecords
      ></el-tab-pane>
      <el-tab-pane label="会费管理" name="third"
      ><membersGLMoney
          v-if="activeName == 'third'"
          :jurisdiction="jurisdiction"
      ></membersGLMoney
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ship from "./ship/ship";
import paymentRecords from "./ship/paymentRecords";
import membersGLMoney from "./ship/membersGLMoney";
import { mapState } from 'vuex';

export default {
  name: "membership",
  components: {
    ship,
    paymentRecords,
    membersGLMoney,
  },
  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("041")
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

