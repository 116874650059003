<template>
  <div class="appointmentList-view">
    <div style="margin-bottom:20px">
      <el-input
          clearable
          v-model="enterpriseName"
          style="width: 200px"
          placeholder="请输入企业名称"
          maxlength="30"
      ></el-input>
      <el-input
          clearable
          v-model="membersName"
          style="width: 200px;margin-left: 8px"
          placeholder="请输入会员名称"
          maxlength="30"
      ></el-input>
      <el-select v-model="value"  placeholder="请选择缴费方式" clearable  style="width: 200px;margin-left: 8px">
        <el-option
            v-for="item in optionList"
            :key="item.index"
            :label="item.name"
            :value="item.index"
        >
        </el-option>
      </el-select>
      <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="queryDate"
          style="margin-left: 8px;width: 200px"
          align="right"
          type="date"
          :clearable="true"
          placeholder="选择缴费日期"
      >
      </el-date-picker>
      <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
      >查询
      </el-button
      >
    </div>
    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="userName" align="center" label="会员名称"/>
        <el-table-column prop="companyName" align="center" label="企业名称"/>
        <el-table-column prop="total" align="center" label="缴费金额"/>
        <el-table-column prop="payType" align="center" label="缴费方式"/>
        <el-table-column prop="endTime" align="center" label="本次缴费至"/>
        <el-table-column prop="payTime" align="center" label="缴费时间"/>
		<el-table-column prop="payTime" align="center" label="缴费凭证">
			<template slot-scope="scope">
				<img @click="showImg(scope.row.payUrl)" v-if="scope.row.payUrl" :src="scope.row.payUrl" width="40" height="40" class="head_pic"/>
				<span v-else>暂无凭证</span>
			</template>
		</el-table-column>
		<el-table-column prop="remark" align="center" label="备注"/>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {selectPayHisPageList} from "@/api/members";

export default {
  name: "paymentRecords",
  components: {
    commonTable,
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enterpriseName: "",
      membersName: "",
      value: "",
      optionList: [{index:0,name:'微信支付'},{index:1,name:'线下支付'}], //全部分类
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
    };
  },
  computed: {

  },
  mounted() {
    this.queryPage();
  },
  methods: {
	  showImg(url) {
		  console.log( url )
		  window.open(url)
		// window.location.href =  url 
	  },
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ifPay:0,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectPayHisPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].payTime) {
            list[i].payTime = this.renderTime(list[i].payTime)
          }
          list[i].total = (list[i].total/100).toFixed(2)
          if (list[i].payType == 0) {
            list[i].payType = '微信支付'
          } else if (list[i].payType == 1) {
            list[i].payType = '线下支付'
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
  var dateee = new Date(date).toJSON();
  return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
},
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      let data = {
        companyName: this.enterpriseName,
        userName: this.membersName,
        payType: this.value,
        payTime: this.queryDate,
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        companyName: this.enterpriseName,
        userName: this.membersName,
        payType: this.value,
        payTime: this.queryDate,
      };
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        companyName: this.enterpriseName,
        userName: this.membersName,
        payType: this.value,
        payTime: this.queryDate,
      };
      this.queryPage(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>

