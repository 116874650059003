<template>
  <div class="appointmentList-view">
    <div style="margin-bottom:20px">
      <el-input
          clearable
          v-model="membersName"
          style="width: 200px;"
          placeholder="请输入会员名称"
          maxlength="30"
      ></el-input>
      <el-input
          clearable
          v-model="enterpriseName"
          style="width: 200px;margin-left: 8px"
          placeholder="请输入企业名称"
          maxlength="30"
      ></el-input>
      <el-date-picker
          v-model="queryDate"
          style="width: 200px;margin-left: 8px"
          align="right"
          type="date"
          :clearable="true"
          placeholder="选择日期"
      >
      </el-date-picker>
      <el-select
          style="width: 200px;margin-left: 8px"
          clearable
          v-model="auditStatus"
          class="mb10 mr10"
          placeholder="选择状态"
      >
        <el-option
            v-for="(item,index) in auditList"
            :key="'info3'+ index"
            :label="item.name"
            :value="item.index"
        >
        </el-option>
      </el-select>
      <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
      >查询
      </el-button
      >
    </div>

    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column
            prop="userName"
            align="center"
            label="会员名称"
            show-overflow-tooltip
        />
        <el-table-column
            prop="nickname"
            align="center"
            label="微信昵称"
            show-overflow-tooltip
        />
        <el-table-column prop="companyName" align="center" label="企业名称"/>
        <el-table-column prop="level" align="center" label="入会级别"/>
        <el-table-column prop="examineStatus" align="center" label="审核状态"/>
        <el-table-column prop="createTime" align="center" label="申请时间"/>
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <customPopconfirm
                style="margin-right: 10px"
                v-if="scope.row.examineStatus=='待审核'"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm='toPass(scope.row)'
                icon="el-icon-info"
                icon-color="red"
                title="确定要通过吗？">
              <el-button slot="reference" type="info" size="mini" plain  class="ma ml10 mb10">通过</el-button>
            </customPopconfirm>
            <el-button type="info" size="mini" plain v-if="scope.row.ifBind==0&&scope.row.examineStatus=='待审核'"  class="ma ml10 mb10" @click="toNoPass(scope.row)">驳回</el-button>
            <el-button size="mini" type="info" plain @click="toDetails(scope.row)">查看详情</el-button>
            <el-button type="danger" size="mini" plain  v-if="scope.row.examineStatus=='已驳回'" class="ma ml10 mb10" @click="toJournal(scope.row)">审批日志</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="查看详情"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <div>
        <div class="title_wrap">
          <div>会员名称:</div>
          <div>{{details.userName}}</div>
        </div>
        <div class="title_wrap">
          <div>性别:</div>
          <div>{{details.gender}}</div>
        </div>
        <div class="title_wrap">
          <div>身份证号:</div>
          <div v-if="details.idNumber">{{details.idNumber}}</div>
          <div v-else>—</div>
        </div>
        <div class="title_wrap">
          <div>企业名称:</div>
          <div v-if="details.companyName">{{details.companyName}}</div>
          <div v-else>—</div>
        </div>
        <div class="title_wrap">
          <div>社会信用代码:</div>
          <div v-if="details.creditCode">{{details.creditCode}}</div>
          <div v-else>—</div>
        </div>

        <div class="title_wrap">
          <div>联系方式:</div>
          <div>{{details.tel}}</div>
        </div>
        <div class="title_wrap">
          <div>申请职位:</div>
          <div>{{details.level}}</div>
        </div>
        <div class="title_wrap">
          <div>助理姓名:</div>
          <div v-if="details.assistantName">{{details.assistantName}}</div>
          <div v-else>—</div>
        </div>
        <div class="title_wrap">
          <div>助理电话:</div>
          <div v-if="details.assistantTel">{{details.assistantTel}}</div>
          <div v-else>—</div>
        </div>
        <div class="title_wrap">
          <div>入会申请书:</div>
          <div v-if="details.applyUrl">
            <img :src="details.applyUrl" alt="">
          </div>
          <div v-else>—</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="审批详情"
        :visible.sync="journalDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <div>
        <el-timeline style="margin-top: 20px">
          <el-timeline-item
              v-for="(item, index) in journalList"
              :key="index"
              :timestamp="item.createTime">
            <p v-if="item.type==0">{{item.content}}</p>
            <p v-if="item.type==1">驳回原因：{{item.content}}</p>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let base64 = require('js-base64').Base64
import commonTable from "@/components/common/commonTable";
import {selectMemberPageList,queryJoinApplyDetail,approvalMember,selectExaminePageList} from "@/api/members";
import {selectMemberFeeList} from '@/api/organization'
import customPopconfirm from "@/components/common/customPopconfirm";

export default {
  name: "ship",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      auditStatus:'',
      auditList: [{index: 1, name: '待审核'}, {index: 0, name: '已审核'}, {index: 2, name: '已驳回'}],
      centerDialogVisible:false,
      journalDialogVisible:false,
      enterpriseName: "",
      membersName: "",
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      optionList: "", //全部分类
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      industryList:[],
      details:{},
      journalList:[]
    };
  },
  computed: {

  },
  mounted() {
    this.queryPageMoney()

  },
  methods: {
    async queryPageMoney() {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await selectMemberFeeList(data);
        this.industryList = result.data;
        this.queryPage();
      } catch (error) {

      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        examineStatusList:'0,1,2',
        ...payload,
      };
      try {
        this.loading = true;
        const result = await selectMemberPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if ( list[i].nickname) {
            list[i].nickname = base64.decode(list[i].nickname);
          }
          list[i].createTime = this.renderTime(list[i].createTime)
          if ( list[i].examineStatus == 0 ) {
            list[i].examineStatus = '已审核'
          }else if (list[i].examineStatus == 1) {
            list[i].examineStatus = '待审核'
          }else if (list[i].examineStatus == 2) {
            list[i].examineStatus = '已驳回'
          }


          for (let j in this.industryList) {
            if (list[i].level == this.industryList[j].memberLevelId) {
              list[i].level = this.industryList[j].levelName
            }
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        userName: this.membersName,
        companyName:this.enterpriseName,
        createTime:this.queryDate,
        examineStatus:this.auditStatus
      };
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        userName: this.membersName,
        companyName:this.enterpriseName,
        createTime:this.queryDate,
        examineStatus:this.auditStatus
      };
      this.queryPage(data);
    },
    toDetails(row){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        userId: row.userId,
        type:0,
        memberId:row.memberId
      }
      queryJoinApplyDetail(ajax)
          .then((res) => {
            this.centerDialogVisible = true
            let details = res.data
            details.gender = details.gender == 1 ? '男' : '女'
            for (let j in this.industryList) {
              if (details.level == this.industryList[j].memberLevelId) {
                details.level = this.industryList[j].levelName
              }
            }
            this.details = details
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    queryAppointmentPage(){
      this.currentPage = 1;
      let data = {
        userName: this.membersName,
        companyName:this.enterpriseName,
        createTime:this.queryDate,
        examineStatus:this.auditStatus
      };
      this.queryPage(data);
    },
    //日志
    toJournal(row){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        memberId: row.memberId,
        tableId:row.memberId,
      }
      selectExaminePageList(ajax)
          .then((res) => {
            this.journalDialogVisible = true
            const { total, list } = res.data.pageInfo;
            for (let i in list) {
              list[i].createTime = this.renderTime(list[i].createTime)
            }
            this.journalList = list;
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    async toPass(row){
      try {
        let ajax = {
          associationId: localStorage.getItem("associationId"),
          memberId:row.memberId,
          type:1,
          examineStatus:0,
          status: 1,
          content:'同意',
          userId:row.userId,
          tel:row.tel,
          companyId:row.companyId
        }
        await approvalMember(ajax);
        await this.queryPage();
      } catch (error) {
        console.log(error)
      }
    },
    toNoPass(row){
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value == '' || value == null){
          this.$message({
            type: 'error',
            message: '请输入驳回原因！'
          });
          return
        } else {
          let ajax = {
            associationId: localStorage.getItem("associationId"),
            memberId:row.memberId,
            type:1,
            status:0,
            examineStatus:2,
            content:value,
            userId:row.userId,
            tel:row.tel,
            companyId:row.companyId
          }
          approvalMember(ajax)
              .then((res) => {
                this.$message({
                  type: 'success',
                  message: '已驳回'
                });
              })
              .catch(err => {

              });
          this.queryPage();
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.title_wrap{
  display: flex;
  margin-top: 20px;
}
.title_wrap>div:first-child{
  width: 100px;
  text-align: right;
}
.title_wrap>div:nth-child(2){
  font-size: 20px;
  margin-left: 50px;
}
.title_wrap img{
  width: 500px;
  height: auto;
}
</style>

