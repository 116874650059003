<template>
  <div class="appointmentList-view">
    <div  style="margin-bottom:20px">
      <el-input
          clearable
          v-model="enterpriseName"
          style="width: 200px"
          placeholder="请输入企业名称"
          maxlength="30"
      ></el-input>
      <el-input
          clearable
          v-model="membersName"
          style="width: 200px;margin-left: 8px"
          placeholder="请输入会员名称"
          maxlength="30"
      ></el-input>
      <el-select v-model="value" placeholder="请选择缴费状态" clearable  style="width: 200px;margin-left: 8px">
        <el-option
            v-for="item in optionList"
            :key="item.index"
            :label="item.name"
            :value="item.index"
        >
        </el-option>
      </el-select>
      <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
      >查询</el-button
      >
    </div>

    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="index" align="center" label="编号"/>
        <el-table-column
            prop="userName"
            align="center"
            label="会员名称"
            show-overflow-tooltip
        />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column prop="tel" align="center" label="负责人电话" />
        <el-table-column prop="status" align="center" label="缴费状态" />
        <el-table-column prop="payDate" align="center" label="缴费时间" />
        <el-table-column prop="endTime" align="center" label="到期时间" />
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <el-button class="" type="text" @click="payCostList(scope.row)">缴费记录</el-button>
            <el-button class="" type="text" @click="manualPayCost(scope.row)">手动缴费</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="缴费记录"
        :visible.sync="ifPayCostList"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <commonTable
          :tableData="costList"
          @handleSizeChange="handleSizeChange1"
          @handleCurrentChange="handleCurrentChange1"
          :currentPage="currentPage1"
          :loading="loading"
          :total="total1"
      >
        <template v-slot:table>
          <el-table-column prop="total" align="center" label="缴费金额" />
          <el-table-column prop="payType" align="center" label="缴费方式" />
          <el-table-column prop="endTime" align="center" label="到期时间" />
          <el-table-column prop="payTime" align="center" label="缴费时间" />
        </template>
      </commonTable>
    </el-dialog>
    <el-dialog
        title="手动缴费"
        :visible.sync="ifManualPayCost"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="name" label="会员名称:">
          <div style="font-weight: bold;color: #333333">{{ruleForm.name}}</div>
        </el-form-item>
        <el-form-item prop="" label="会员企业:">
          <div style="font-weight: bold;color: #333333">{{ruleForm.companyName}}</div>
        </el-form-item>
        <el-form-item prop="" label="当前状态:">
          <div style="font-weight: bold;color: red" v-if="ruleForm.status==2">已欠费</div>
          <div style="font-weight: bold;color: green" v-if="ruleForm.status==0">已缴纳</div>
          <div style="font-weight: bold;color: #333333" v-if="ruleForm.status==1">待缴费</div>
        </el-form-item>
        <el-form-item prop="date" label="本次缴纳至:">
          <el-date-picker
              clearable
              value-format="yyyy-MM-dd"
              style="width: 100%"
              v-model="ruleForm.date"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="money" label="缴费金额:">
          <el-input
              clearable
              :maxlength="inputMaxL"  @input="inputMaxL = /^\d+\.?\d{0,1}$/.test(ruleForm.money) ? null : ruleForm.money.length - 1"
              v-model="ruleForm.money"
              style="width: 100%"
              placeholder="请输入金额"
          ></el-input>
        </el-form-item>
        <el-form-item prop="desc" label="备注:">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
        <el-form-item prop="imgUrl" label="缴费凭证">
<!--          <el-upload :action="uploadUrl"-->
<!--                     list-type="picture-card"-->
<!--                     :class="{ disabled: uploadDisabled }"-->
<!--                     :on-remove="handleRemove"-->
<!--                     :on-success="handleSuccess"-->
<!--                     :file-list="fileList"-->
<!--                     :limit="1">-->
<!--            <i slot="default" class="el-icon-plus"></i>-->
<!--          </el-upload>-->
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifManualPayCost = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {selectMemberPageList,queryMemberTotalByUserId,addPayHis,selectPayHisPageList} from "@/api/members";

export default {
  name: "membersGLMoney",
  components: {
    commonTable,
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6
        }
      },
      inputMaxL:10,
      centerDialogVisible:false,
      ifManualPayCost:false,
      ifPayCostList:false,
      enterpriseName: "",
      membersName: "",
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      optionList: [{index:0,name:'已缴费'},{index:1,name:'待缴费'},{index:2,name:'已过期'}], //全部分类
      tableData: [],
      total: 0, //总条数
      total1: 0, //总条数
      currentPage: 1, //当前页数
      currentPage1: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      pageNum1: 1,
      pageSize1: 10,
      loading: false, //加载中
      ruleForm:{
        name:'',
        date:'',
        companyName:'',
        money:'',
        status:'',
        desc:'',
        imgUrl:'',
      },
      fileList:[],
      rules:{
        money: [{required: true, message: "请输入金额", trigger: "blur"}],
        date: [{required: true, message: "请选择日期", trigger: "blur"}],
      },
      dialogImageUrl: "",
      dialogVisible:false,
      uploadUrl: this.$store.state.uploadingUrl,
      userId:'',
      memberId:'',
      costList:[],
      ossUrl: this.$store.state.ossUrl,
    };
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        examineStatus:0,
        ifBind:0,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectMemberPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].payDate) {
            list[i].payDate = this.renderTime(list[i].payDate)
          }
          if (list[i].endTime) {}else{list[i].endTime=''}
          if (list[i].endDate) {
            list[i].endDate = this.renderTime(list[i].endDate)
          }
          if (list[i].status == 0) {
            list[i].status = '已缴费'
          } else if (list[i].status == 1) {
            list[i].status = '待缴费'
          } else if (list[i].status == 2) {
            list[i].status = '已过期'
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      let data = {
        companyName:this.enterpriseName,
        userName:this.membersName,
        status:this.value
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        companyName:this.enterpriseName,
        userName:this.membersName,
        status:this.value
      }
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        companyName:this.enterpriseName,
        userName:this.membersName,
        status:this.value
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.payCostList();
    },
    //当前页查询数据条数变更
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.payCostList();
    },
    payCostList(row){
      let ajax = {
        userId:row.userId,
        associationId: localStorage.getItem("associationId"),
        pageSize:this.pageSize1,
        pageNum: this.pageNum1,
      }
      selectPayHisPageList(ajax)
          .then((res) => {
            console.log(res)
            this.ifPayCostList = true
            if (res.data) {
              const { total, list } = res.data.pageInfo;
              for (let i in list) {
                list[i].total = (list[i].total/100).toFixed(2)
                if (list[i].payTime) {
                  list[i].payTime = this.renderTime(list[i].payTime)
                }
                if (list[i].payType == 0) {
                  list[i].payType = '微信支付'
                } else if (list[i].payType == 1) {
                  list[i].payType = '线下支付'
                }
              }
              this.costList = list;
              this.total1 = total;
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    manualPayCost(row){
      this.userId = row.userId
      this.memberId = row.memberId
      let ajax = {
        userId:row.userId
      }
      queryMemberTotalByUserId(ajax)
          .then((res) => {
            this.ifManualPayCost = true
            let details = res.data
            this.ruleForm.name = details.userName
            this.ruleForm.companyName = details.companyName
            this.ruleForm.status = details.status
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    close(){
      (this.ruleForm = {
        money:"",
        imgUrl:"",
        desc: "",
        date:""
      }),
          this.fileList = []
          this.ifManualPayCost = false;
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.imgUrl = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.imgUrl = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              userId:this.userId,
              userName: this.ruleForm.name,
              companyName: this.ruleForm.companyName,
              total: parseInt(this.ruleForm.money * 100),
              remark: this.ruleForm.desc,
              payUrl: this.ruleForm.imgUrl,
              endTime: this.ruleForm.date,
              payType:1
            };
            this.$message({
              message: "正在保存",
            });
            await addPayHis(data);
            this.ifManualPayCost = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message({
              message: error.message,
              type: "error",
            });
            this.ifManualPayCost = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
